<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="企业名称">
                <el-input @change="onQuerySubmit" v-model="query.enterprise_name" placeholder="企业名称"></el-input>
              </el-form-item>
              <el-form-item label="处理人员">
                <el-select v-model="query.handle_user" placeholder="处理人员">
                  <el-option v-for="item in res.user" :key="item.id" :label="item.name" :value="item.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-date-picker v-model="dayValue" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit">查询</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="tableData.data" stripe style="width: 100%">
              <el-table-column prop="enterprise_name" label="企业名称" min-width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="equipment_name" label="排口名称" min-width="180">
              </el-table-column>
              <el-table-column label="完成情况">
                <template slot-scope="scope">
                  {{scope.row.finish === 1 ? '完成' : '未完成'}}
                </template>
              </el-table-column>
              <el-table-column prop="remark" label="备注信息" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="handle_user" label="处理人员">
              </el-table-column>
              <el-table-column prop="created_at" label="创建时间" min-width="180">
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button @click="reagentDatail(scope.row)" type="text" size="small">详情</el-button>
                  <el-button type="text" size="small" @click="deleteItem(scope.row)" v-if="permission.apiFaultRecordFaultDelete">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination layout="prev, pager, next" prev-text="上一页" next-text="下一页" background :page-size="10" @current-change="changeCurrent" :current-page="query.page" :total="tableData.total"> </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiFaultRecordFaultList, apiFaultRecordFaultDelete, apiOutletCondition } from "src/api/index";
import md5 from 'js-md5';
export default {
  data() {
    return {
      loading: false,
      tableData: {},
      res: {},
      query: {
        enterprise_name: '',
        handle_user: '',
        start_time: '',
        end_time: '',
        page: 1,
        logotype: 'maintainEarly'
      },
      dayValue: '',
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5('query')))
    if (query === null) {
      _this.query = _this.query
    } else if (query.logotype === 'maintainEarly') {
      _this.query =  query;
      _this.dayValue = [query.start_time, query.end_time]
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    _this.changeCurrent(_this.query.page);
    //获取处理人员数据
    apiOutletCondition()
		.then((lists) => {
			_this.res = lists;
		})
		.catch((err) => {
			_this.error(err);
		});
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    url: (state) => state.url,
    permission: (state) => state.permission,
  }),
  watch: {
    '$route': 'getEarlyData'
  },
  methods: {
    ...mapActions(["pageInit"]),
    //获取处理记录数据
    onQuerySubmit() {     
      this.query.start_time = this.dayValue[0];
      this.query.end_time = this.dayValue[1];
      this.query.page = 1;
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&enterprise_name=' + this.query.enterprise_name + '&handle_user=' + this.query.handle_user + '&start_time=' + this.query.start_time + '&end_time=' + this.query.end_time;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getEarlyData();
      } else {       
        this.$router.replace(route);
      }
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item
      let route = this.$router.history.current.path + '?page=' + this.query.page + '&enterprise_name=' + this.query.enterprise_name + '&handle_user=' + this.query.handle_user + '&start_time=' + this.query.start_time + '&end_time=' + this.query.end_time;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getEarlyData();
      } else {       
        this.$router.replace(route);
      }
    },
    getEarlyData() {
      const _this = this;
      _this.loading = true;
      apiFaultRecordFaultList(_this.query)
      .then((res) => {
        _this.loading = false;
        _this.tableData = res;
      })
      .catch((err) => {
        _this.loading = false;
        _this.error(err);
      }) 
    },
    //删除处理记录
    deleteItem(item) {
      const _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        apiFaultRecordFaultDelete({id:item.id})
        .then((res) => {
          _this.$message({
            message: "删除成功",
            type: "success",
          });
          _this.onQuerySubmit();
        })
        .catch((err) => {
          _this.error(err);
        }); 
      })
    },
    //跳转详情页
    reagentDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push('/admin/maintain/early-detail/' + item.id)
    },
    //错误信息提示
    error(err) {
      if (err.message === '没有操作权限') {
        return
      } else {         
        this.$message.error(err.message);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
    position: relative;
    margin-top: 7px;
}
</style>